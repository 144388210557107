<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
              v-if="!props.user?.uuid"
            >
              <ElFormItem
                prop="user_uuid"
                :label="$t('user')"
                @keydown.enter.prevent="submit(ruleFormRef)"
                class="autocomplete-new-entity"
                :class="form.user_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getUserKey()"
                  v-loading="getUserKey() === 0"
                  v-model="form.user_uuid"
                  :remote-method="searchUsers"
                  :placeholder="$t('select_an_user')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchUsers()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewUserDialog = true"
                />
                <Icons
                  v-if="form.user_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/configuration/users', {
                      uuid: form.user_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="membership_uuid"
                :label="$t('membership')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getMembershipKey()"
                  v-loading="getMembershipKey() === 0"
                  v-model="form.membership_uuid"
                  :remote-method="searchMemberships"
                  :placeholder="$t('select_an_option')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchMemberships(null, null, t)"
                  style="width: 100%"
                  @change="onMembershipChange"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewMembershipDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="start_at" :label="$t('start_date')">
                <ElDatePicker
                  v-model="form.start_at"
                  class="!w-full"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                  @change="setEndAt()"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewUserDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_user') }}
        </span>
      </template>
      <QuickCreateUserDialog
        @create=";(showNewUserDialog = false), createUser($event)"
        @cancel="showNewUserDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewMembershipDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_membership') }}
        </span>
      </template>
      <QuickCreateMembershipDialog
        @create=";(showNewMembershipDialog = false), createMembership($event)"
        @cancel="showNewMembershipDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import moment from 'moment'
import 'moment/dist/locale/es'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_USER_MEMBERSHIP from '~/graphql/user-memberships/mutation/createUserMembership.gql'

const props = defineProps({
  user: {
    type: Object,
    default: () => {}
  }
})

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  membership_uuid: '',
  user_uuid: props.user?.uuid || '',
  start_at: '',
  end_at: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
let { newWindowItem } = getTableInitialVariables()

const ruleFormRef = ref()
const loading = ref(false)
const showNewUserDialog = ref(false)
const showNewMembershipDialog = ref(false)

onMounted(() => {
  initialUsers()
  initialMemberships()
})

const inputRules = reactive({
  user_uuid: [
    {
      required: true,
      message: t('user_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  membership_uuid: [
    {
      required: true,
      message: t('membership_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  start_at: [
    {
      required: true,
      message: t('start_at_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const rules = {
  user_uuid: { required },
  membership_uuid: { required },
  start_at: { required }
}

const v$ = useVuelidate(rules, form)

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  moment.locale('es')
  const response = await mutation(CREATE_USER_MEMBERSHIP, {
    membership_uuid: form.membership_uuid,
    user_uuid: form.user_uuid,
    start_at: form.start_at
      ? moment(form.start_at).format('YYYY-MM-DD 00:00:00')
      : null,
    end_at: form.end_at
      ? moment(form.end_at).format('YYYY-MM-DD 00:00:00')
      : null,
    requested_at: moment().format('YYYY-MM-DD HH:mm:ss')
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createUserMembership ?? null)
    loading.value = false
  }
}

const onMembershipChange = async (uuid) => {
  if (getSearchMemberships(null, null, t)?.length && uuid) {
    const membership = getSearchMemberships(null, null, t).find(
      (item) => item.value === uuid
    )
    const results = await membershipsAutocomplete({
      search: membership?.label?.split(' ')[0] ?? ''
    })
    if (results?.getAllMemberships?.data?.find((item) => item.uuid === uuid)) {
      const item = results.getAllMemberships.data.find(
        (item) => item.uuid === uuid
      )
      form.membership = item
      setEndAt()
    }
  }
}

const setEndAt = () => {
  moment.locale('es')
  if (form.start_at && form.membership) {
    form.end_at = moment(form.start_at).add(form.membership.months, 'month')
  }
}

const createUser = (event) => {
  if (event?.uuid) {
    form.user_uuid = event.uuid
    form.user = event
    form.full_name = event.first_name + ' ' + event.last_name
    searchUsers(event.first_name, form.user, null, true)
  }
}

const createMembership = (event) => {
  if (event?.uuid) {
    moment.locale('es')
    form.membership_uuid = event.uuid
    form.membership = event
    form.end_at = form.start_at
      ? moment(form.start_at).add(event.months, 'month')
      : null
    searchMemberships(event.type, form.membership, null, true, t)
  }
}
</script>
